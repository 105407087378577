* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.githubPage{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}
.contactPage{
  width: 100%;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}
.githubHeading{
  text-align: center;
  display: flex;
  padding-top: 20px;
  width: 100%;
  color: #fff;
  justify-content: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}
.contactHeading{
  text-align: center;
  display: flex;
  padding-top: 20px;
  width: 100%;
  color: #000000;
  justify-content: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}
.resourceHeading{
  text-align: center;
  display: flex;
  padding-top: 40px;
  width: 100%;
  color: #fff;
  justify-content: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}
.resourceHeading2{
  text-align: center;
  display: flex;
  margin-top: 70px;
  width: 100%;
  color: #fff;
  justify-content: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}
.githubImage{
  height: 100%;
  width: 100%;
  z-index: -999;
  position:absolute;
  clip-path: circle(50%);
}
.resourceImage{
  height: 30%;
  width: 100%;
  z-index: -999;
  position:absolute;
}

.professionalPhoto{
  height: 300px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 50%;
}

.resourcePage {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 165px;
}

.resourcePage h2 {
  font-size: 2rem;
  color: #333;
  margin-top: 40px;
  margin-bottom: 10px;
}

.resourcePage ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.resourcePage li {
  margin-bottom: 10px;
}

.resourcePage li a {
  color: #333;
  text-decoration: none;
}

.resourcePage li a:hover {
  text-decoration: underline;
}

.aboutMe{
  line-height: 1.5;
  font-size: 16px;
  margin-bottom: 1em;
  font-weight: bold;
  color: #333;
  text-align: center;
  padding: 10px;
}

/* Table CSS */ 
table {
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  color: #333;
}

table th {
  background-color: #dfe6e9;
  text-align: left;
  padding: 10px;
  font-weight: bold;
}

table td {
  border: 1px solid #dfe6e9;
  padding: 10px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table a {
  color: #3498db;
  text-decoration: none;
}

table a:hover {
  color: #2980b9;
  text-decoration: underline;
}

/* Mobile CSS */ 
@media screen and (max-width: 960px) {
  .githubImage{
    height: auto;
    width: 100%;
    z-index: -999;
    position:absolute;
    clip-path: none;
    top: 80px;
  }
}
  